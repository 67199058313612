import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import AudioService from "../services/AudioService";
import PrimaryButton from "./PrimaryButton";
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: black;
  .btn-play {
    position: absolute;
  }

  video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;}
`;
const VideoPlayer = ({
  video,
  onEnd,
  mute,
  volume,
  autoPlay,
  width,
  height,
}) => {
  const videoPlayer = useRef(null);
  const { setMute } = AudioService.useContainer();
  const [playing, setPlaying] = useState(false);
  const [control, setControl] = useState(false);

  const _onPlay = () => {
    setMute(false);
  };

  const handleClickPlay = () => {
    setPlaying(true);
  };

  const handleEnd = () => setControl(true)

  console.log({control})

  return (
    <Wrapper>
      <ReactPlayer
        url={video}
        width={width}
        height={height}
        onEnded={onEnd}
        playing={playing}
        volume={volume}
        muted={!mute}
        ref={videoPlayer}
        playsinline
        controls={true}
        vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
        // onPlay={() => _onPlay()}
        // playIcon={
        // <div
        //   className="play-icon"
        //   role="button"
        //   tabIndex={0}
        //   style={{ outline: "none" }}
        // >
        //   <img src="/images/icons/mute.png" alt="mute" />
        // </div>
        // }
        // onPause={() => setPlaying(false)}
        // // onClickPreview={() => setPlaying(true)}
      />
      {!playing && (
        <PrimaryButton className="btn-play" onClick={handleClickPlay}>
          Play
        </PrimaryButton>
      )}
    </Wrapper>
  );
};
export default VideoPlayer;
