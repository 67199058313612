import React, { useEffect, useState } from "react";
import Background from "../components/Background";
import Bench from "../components/Bench";
import BackButton from "../components/BackButton";
import ContentButton from "../components/ContentButton";
import ContentPopup from "../components/ContentPopup";
import CharacterGroup from "../components/CharacterGroup";
import PrimaryButton from "../components/PrimaryButton";
import ChapterService from "../services/ChapterService";
import NavigationService from "../services/NavigationService";
import styled from "styled-components";
import { characterType } from "../constants";
import { withRouter } from "react-router-dom";
const Wrapper = styled.div`
  height: 100%;
  width: fit-content;
  .outbox {
    width: 100%;
    height: 100%;
  }

  .inbox {
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .inbox {
    .button-list {
      display: flex;
      height: 100%;
      padding-top: 0;
      z-index: 999;
    }
  }

  .group {
    display: inline-block;
    position: unset;
    width: 33.333%;
    bottom: 0;
    padding-bottom: 50px;
    @media screen and (max-width: 1180px) and (max-height: 500px) {
      padding-bottom: 0;
      .teacher {
        position: unset;
      }
    }
    &.left {
      left: 0;
      .teacher {
        width: 100%;
        height: auto !important;
        min-height: auto;
        margin: auto;
        @media (max-width: 1280px) {
          width: 100%;
        }
      }
    }
    &.right {
      right: 0;
      .teacher {
        width: 100%;
        height: auto !important;
        margin: auto;
        @media (max-width: 1280px) {
          width: 100%;
        }
      }
    }
    @media (max-width: 550px) {
      width: 40%;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }

  @media (max-width: 1015px) {
    .group .teacher {
      width: 100% !important;
      bottom: 100px !important;
    }
  }

  @media (max-width: 768px) {
    .group {
      .teacher {
        height: auto !important;
      }
    }
  }
  .character {
    position: relative;
  }

  .button-list {
    display: inline-block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .button.hollow.primary {
      margin-bottom: 0;
    }
    ul {
      list-style: none;
      li {
        padding: 20px;
        button {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .button-list {
      .button {
        font-size: 1rem;
      }
    }
  }
  @media (max-width: 812px) and (max-height: 450px) {
    .left {
      left: 0 !important;
    }
    .right {
      right: 0 !important;
    }
    .button-list {
      ul {
        li {
          padding: 0;
          padding-bottom: 20px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .left {
      left: -10%;
    }
    .right {
      right: -10%;
    }
  }
  @media (min-width: 1024px) {
    .left {
      left: 0%;
    }
    .right {
      right: 0%;
    }
  }
  @media (min-width: 1366px) {
    .left {
      left: 0%;
    }
    .right {
      right: 0%;
    }
  }
  @media screen and (max-width: 1180px) and (max-height: 768px) {
    .group .teacher {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;
// const Characters = posed.div({
//   offScreenRight: {
//     x: "1000px",
//     opacity: 0,
//     transition: { duration: 1000 },
//   },
//   exitRight: {
//     x: "1000px",
//     opacity: 0,
//     transition: { duration: 1000 },
//   },
//   right: {
//     x: "450px",
//     opacity: 1,
//     transition: { duration: 1000 },
//   },
//   left: {
//     x: "-500px",
//     opacity: 1,
//     transition: { duration: 1000 },
//   },
//   offScreenLeft: {
//     x: "-1000px",
//     opacity: 0,
//     transition: { duration: 1000 },
//   },
//   exitLeft: {
//     x: "-1000px",
//     opacity: 0,
//     transition: { duration: 1000 },
//   },
// });
const TeachersPortal = ({ history }) => {
  const { chapter, getCharacters } = ChapterService.useContainer();
  const { setShowBack, setBackCallback, setGoBack, setShowContent } =
    NavigationService.useContainer();
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  // const [exitPose, setExitPose] = useState(false);
  const [show, setShow] = useState(false);
  const sortCharacters = (chars) => {
    chars.map((c, index) => {
      if (c.type === characterType.TEACHER) {
        if (index % 2 === 0) {
          setLeft((left) => left.concat(c));
        } else {
          setRight((right) => right.concat(c));
        }
      }
    });
  };

  setBackCallback(function () {
    // setExitPose(true);
    setGoBack(true);
  });
  useEffect(() => {
    setShowBack(true);
    setGoBack(false);
    setShowContent(true);
    sortCharacters(getCharacters());
    localStorage.setItem("playAnimation", false);
  }, [setShowBack]);
  const handleClose = () => {
    setShow(false);
  };
  return (
    <Wrapper>
      <Background>
        <div className="outbox">
          <div className="inbox">
            <CharacterGroup
              characters={left}
              charClassName="teacher character"
              className="group left"
              alignment="flex-start"
            />
            <div className="button-list">
              <ul>
                <li>
                  {/* <PrimaryButton
                    onClick={() => window.open(chapter.teacher_notes, "_blank")}
                  >
                    DOWNLOAD TEACHER NOTE'S
                  </PrimaryButton> */}
                  <a
                    className="hollow button primary"
                    href={"/Teacher.pdf"}
                    download="Teacher Handbook: The Case of The Starving Slum Lane Students and the Empty School Canteen.pdf"
                  >
                    Download Teacher Notes
                  </a>
                </li>
                <li>
                  {/* <PrimaryButton
                    onClick={() => window.open(chapter.student_notes, "_blank")}
                  >
                    DOWNLOAD STUDENT NOTE'S
                  </PrimaryButton> */}
                  <a
                    className=" hollow button primary"
                    href={"/Student.pdf"}
                    download="Student Handbook: The Case of The Starving Slum Lane Students and the Empty School Canteen.pdf"
                  >
                    Download Student Notes
                  </a>
                </li>
                <li>
                  <PrimaryButton onClick={() => setShow(true)}>
                    ANIMATIONS
                  </PrimaryButton>
                </li>
                <li>
                  <a
                      className=" hollow button primary"
                      href={"./additionalLearningGuide.pdf"}
                      download="Additional Learning Guide.pdf"
                  >
                    Additional Learning Guides
                  </a>
                </li>
              </ul>
            </div>
            <CharacterGroup
              characters={right}
              charClassName="teacher character"
              className="group right"
              alignment="flex-end"
            />
          </div>
        </div>
        <Bench />
      </Background>
      <BackButton />
      <ContentButton />
      <ContentPopup show={show} onClose={handleClose} />
    </Wrapper>
  );
};
export default withRouter(TeachersPortal);
