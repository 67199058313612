import React, { useState, useEffect, useCallback, useRef } from 'react';
import SpeechBubble from './SpeechBubble';
import CharacterPopup from './CharacterPopup';
import ChapterService from '../services/ChapterService';
import styled from 'styled-components';
import posed from 'react-pose';
const Character = posed.div({
  center: {
    left: '50%',
    x: '-50%',
    y: '-50%',
    opacity: 1,
    transition: { duration: 1000 },
  },
  offscreenLeft: {
    left: '0%',
    x: '-50%',
    y: '0',
    opacity: 0,
    transition: { duration: 1000 },
  },
  left: {
    left: '20%',
    x: '-50%',
    y: '0',
    opacity: 1,
    transition: { duration: 1000 },
  },
  offscreenRight: {
    left: '100%',
    x: '-50%',
    y: '0',
    opacity: 0,
    transition: { duration: 1000 },
  },
  right: {
    left: '80%',
    x: '-50%',
    y: '0',
    opacity: 1,
    transition: { duration: 1000 },
  },
});
const StyledCharacter = styled(Character)`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 550px;
  height: 750px;
  button.button.secondary {
    z-index: 99;
  }

  @media only screen and (max-width: 1440px) {
    width: 450px;
    height: 650px;
  }

  .character-container {
    position: relative;
    display: contents;
    justify-content: center;

    img {
      position: unset;
      bottom: 0px;
      pointer-events: ${(props) => props.pointerEvent};
      cursor: ${(props) => props.cursorType};
      min-height: auto;
      top: 0;
      left: 0;
      width: 80%;
      height: 80%;
      @media only screen and (min-width: 1800px) {
        width: 100%;
        height: 100%;
      }
      @media only screen and (min-width: 1366px) {
        width: 75%;
        height: 75%;
      }
      @media (min-width: 1280px) and (max-width: 1366px) {
        width: 70%;
        height: 70%;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
      }
    }
    .grow {
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }

    .left-speech-bubble {
      position: absolute;
      top: 10%;
      left: 80%;
      width: 500px;
      font-size: 12px;
      width: 275px;
      left: 90%;
      @media (min-width: 600px) {
        font-size: 12px;
        width: 275px;
        left: 90%;
        top: 20%;
      }

      @media (min-width: 768px) {
        font-size: 14px;
        width: 270px;
        left: 90%;
        top: 10%;
      }
      @media (min-width: 1024px) {
        width: 400px;
        font-size: 16px;
        left: 80%;
        top: 10%;
      }
      @media (min-width: 1366px) {
        font-size: 24px;
      }

      @media (max-width: 600px) {
        top: 10%;
        left: 90%;
        min-width: 190px;
        width: auto;
        margin-right: 20px;
      }
      @media (max-width: 400px) {
        top: -10%;
        left: 100%;
      }
      @media screen and (max-width: 1180px) and (max-height: 768px) {
        left: 90%;
        top: 0;
      }

      @media screen and (max-width: 1180px) and (max-height: 500px) {
        left: 100%;
        top: 0;
      }
    }
    .right-speech-bubble {
      position: absolute;
      right: 80%;
      width: 500px;
      top: 5%;
      font-size: 12px;
      width: 275px;
      @media (min-width: 600px) {
        font-size: 12px;
        width: 275px;
        top: 20%;
      }
      @media (min-width: 768px) {
        font-size: 14px;
        width: 270px;
        top: 10%;
      }
      @media (min-width: 1024px) {
        font-size: 16px;
        width: 300px;
        top: 10%;
      }
      @media (min-width: 1366px) {
        font-size: 24px;
        top: 10%;
        width: 400px;
      }

      @media (max-width: 600px) {
        top: 15%;
        right: 90%;
        min-width: 190px;
        width: auto;
      }
      @media (max-width: 400px) {
        top: -10%;
        right: 100%;
        margin-left: 20px;
      }
      @media screen and (max-width: 1180px) and (max-height: 768px) {
        right: 90%;
        top: -10%;
      }
      @media screen and (max-width: 1180px) and (max-height: 500px) {
        right: 90%;
        top: -10%;
      }
    }
  }
`;
export default ({
  character,
  actions,
  text,
  onTextEnd,
  audio,
  onAudioEnd,
  onAnimationEnd,
  className,
  interactable,
  hoverable,
}) => {
  const char = useRef(null);
  const { getCharacters, getTools } = ChapterService.useContainer();
  const [alignment, setAlignment] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [index, setIndex] = useState(0);
  const [filteredToolList, setFilteredToolList] = useState([]);
  const handleNextAction = useCallback(() => {
    if (actions && actions.length > 0) {
      if (index < actions.length) {
        setIndex((index) => index + 1);
      }
    }
  }, [actions, index]);
  useEffect(() => {
    handleNextAction();
    if (char.current !== null) {
      if (
        char.current.getBoundingClientRect().x >=
        document.documentElement.clientWidth / 2
      ) {
        setAlignment('right');
      } else {
        setAlignment('left');
      }
    }
  }, [handleNextAction, char]);
  const handleOnPoseComplete = (pose) => {
    if (pose === undefined) {
      return;
    }
    handleNextAction();
    if (actions && index === actions.length - 1) {
      if (onAnimationEnd !== undefined) {
        onAnimationEnd();
      }
    }
  };
  const handleClick = () => {
    const toolList = getTools();
    const characterTools = character.questions[2].tools;
    let filteredTool = [];

    characterTools.map((tool) => {
      toolList
        .filter((tools) => tools.id === tool)
        .map((filteredTools) => filteredTool.push(filteredTools));
    });
    setFilteredToolList({ filteredTool });
    filteredTool = [];

    if (interactable) {
      setShowPopup(true);
    }
  };
  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <StyledCharacter
      pose={actions && actions.length > 0 ? actions[index] : ''}
      onPoseComplete={(pose) => handleOnPoseComplete(pose)}
      className={className}
      pointerEvent={interactable ? 'all' : 'none'}
      cursorType={interactable ? 'pointer' : 'default'}
    >
      <div className='character-container'>
        <img
          src={character.image.source}
          alt={character.image.alt}
          ref={char}
          onClick={() => handleClick()}
          className={hoverable ? 'grow' : ''}
        />

        <SpeechBubble
          className={`${alignment}-speech-bubble`}
          alignment={alignment}
          text={text}
          audio={audio}
          onAudioEnd={() => onAudioEnd()}
          onTextEnd={() => onTextEnd()}
        />
        <CharacterPopup
          character={character}
          show={showPopup}
          onClose={() => handleClose()}
          filteredTool={filteredToolList}
        />
      </div>
    </StyledCharacter>
  );
};
