import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    body {
      height: 100%;
      #root {
        height: 100%;
        .app {
          height: 100%;

          .characters {
            position: absolute;
            margin: 0;              
            min-height: auto;
            bottom: 0;
            .padding{
              padding: 0;
              width: 33.333%;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              padding-bottom: 120px;
              @media screen and (max-width: 1180px) and (max-height: 500px) {
                padding-bottom: 50px;
              }
              @media (max-width: 1280px) {
                padding-bottom: 80px;
              }
            }
            @media (max-width: 768px) {
              .padding{
                padding-bottom: 80px;
              }
            }
          }
          .student {
            position: relative;
            height: auto;
            min-height: auto;
            width: 80%;
            @media (max-width: 1440px) {
              width: 100%;
            }
            @media (max-width: 1280px) {
              width: 80%;
            }
            @media (max-width: 1024px) {
              width: 100%;
            }
            @media screen and (max-width: 1180px) and (max-height: 500px) {
              width: 70%;
            }
          }
        }
      @media screen and (max-width: 1180px) and (max-height: 768px) {
        .group{
          .teacher {
            width: 100% !important;
            height: 100% !important;
            position: unset;
          }
        }
      }
      @media screen and (max-width: 991px) and (max-height: 400px) {
        .group{
          .teacher {
            width: 78% !important;
            height: 100% !important;
            position: unset;
          }
        }
      }
      @media (max-width: 1280px) {
        .group{
          .teacher {
            .character-container{
              img{
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      // @media (max-width: 600px) {
      //   .teacher-content{
      //     padding-bottom: 30px;
      //     .teacher{
      //       width: auto !important;
      //       height: auto !important;
      //       transition: all .5s ease-in-out;
      //       visibility: visible;
      //       position: initial;
      //       transform: none !important;
      //       .character-container .left-speech-bubble {
      //         left: 50%;
      //         top: 0;
      //         width: auto;
      //         margin-right: 20px;
      //       }
      //       .character-container .right-speech-bubble {
      //         right: 50%;
      //         top: 0;
      //         width: auto;
      //         margin-left: 20px;
      //       }
      //     }
      //   }
      // }
      // @media (max-width: 400px)  {
      //   .teacher-content{
      //     padding-bottom: 60px;
      //     .teacher{
      //       .character-container .left-speech-bubble {
      //         top: -40px;
      //         z-index: 999;
      //       }
      //       .character-container .right-speech-bubble {
      //         top: -40px;
      //         z-index: 999;
      //       }
      //     }
      //   }
      // }
      .teacher {
        top: unset !important;
        bottom: 0;
        @media (max-width: 1015px) {
          width: 350px;
          height: 525px;
        }
        @media (max-width: 768px) {
          width: 350px;
          height: 475px;
        }
        @media (max-width: 600px) {
          width: 50%;
          height: 40vh;
        }
        @media (max-width: 400px) {
          height: 35vh;
        }
        @media screen and (max-width: 1180px) and (max-height: 768px) {
          width: 280px !important;
          height: 375px !important;
        }
        @media screen and (max-width: 1180px) and (max-height: 640px) {
          width: 280px !important;
          height: 375px !important;
        }
        @media screen and (max-width: 360px) and (max-height: 640px) {
          width: 66% !important;
          height: 66vh !important;
        }
        @media screen and (max-width: 1180px) and (max-height: 500px) {
          width: 200px !important;
          height: 260px !important;
        }
      }
    }
  }
}
`;
export default GlobalStyle;
