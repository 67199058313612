import React from 'react'
import styled from 'styled-components'
export default ({ children, className, otherClassName, id, onClick, disabled}) => {
  const handleClick = () => {
    if(onClick !== null || onClick !== undefined) {
      onClick()
    }
  }
  return (
    <Box className={`${className}`}>
      <button className={` ${otherClassName} hollow button primary`} id={id} type="button" onClick={() => handleClick()} disabled={disabled ? true : false}>
      {children}
    </button>
    </Box>
   
  )
}
const Box = styled.div`
  button {
    z-index:1000;
  }
  
  @media (max-width:600px) {
    /* margin-top:-30px; */
  }
  
`