import React from 'react'
import styled from 'styled-components'
const Wrapper = styled.div`
  width: 100%;
  bottom: 0;
  height: 250px;
  position: absolute;
  background-image: url(${'/assets/media/images/background/bench.png'}); /* The image used */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  z-index: 99;
  @media (max-width: 768px) {
    height: 160px;
  }
  @media screen and (max-width: 1180px) and (max-height: 768px) {
    height: 160px;
  }
  @media screen and (max-width: 1180px) and (max-height: 500px) {
    height: 100px;
  }
`
export default ({ children, className }) => {
  return (
    <Wrapper className={className}>
      { children }
    </Wrapper>
  )
}
