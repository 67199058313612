import React, { useEffect, useState } from "react";
import Character from "./Character";
import styled from "styled-components";
const Wrapper = styled.ul`
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: center;
  margin: 0;
  bottom: 50px;
  position: absolute;
  @media (min-width: 1366px) {
    bottom: -50px;
  }

  @media (max-width: 1366px) {
    bottom: -20px;
  }

  @media (min-width: 1280px) and (max-width: 1366px) {
    bottom: -90px;
  }

  @media (max-width: 768px) {
    bottom: 0;
  }

  @media (max-width: 600px) {
    bottom: 30px;
  }

  @media (max-width: 400px) {
    bottom: 40px;
  }
  @media screen and (max-width: 1180px) and (max-height: 768px) {
    bottom: 0;
  }
  @media screen and (max-width: 1180px) and (max-height: 500px) {
    bottom: 0;
  }
  .padding {
    padding: 0 1%;
    .teacher-popup {
      z-index: 99;
    }
  }
`;
export default ({
  characters,
  className,
  charClassName,
  interactable,
  hoverable,
  alignment,
  onAnimationEnd,
  onTextEnd,
  onAudioEnd,
  skip,
  indexChar,
}) => {
  const [_characters, setCharacters] = useState([]);
  useEffect(() => {
    setCharacters(characters);
  }, [characters]);
  const onSetClass = (indexChar, index) => {
    return indexChar % 2 === index % 2 ? "teacher-popup" : "";
  };
  const checkAudio = (audio) => {
    if (audio !== undefined && !skip) {
      return audio;
    }
    return null;
  };
  const checkText = (text) => {
    if (text !== undefined && !skip) {
      return text;
    }
    return null;
  };
  return (
    <Wrapper alignment={alignment} className={className}>
      {_characters.map((character, index) => {
        return (
          <li key={index} className="padding teacher-content">
            <Character
              character={character}
              actions={character.actions}
              text={checkText(character.text)}
              audio={checkAudio(character.audio)}
              className={charClassName + " " + onSetClass(indexChar, index)}
              onAnimationEnd={
                onAnimationEnd !== undefined ? () => onAnimationEnd() : null
              }
              onTextEnd={onTextEnd !== undefined ? () => onTextEnd() : null}
              onAudioEnd={onAudioEnd !== undefined ? () => onAudioEnd() : null}
              interactable={interactable}
              hoverable={hoverable}
            />
          </li>
        );
      })}
    </Wrapper>
  );
};
