import React, { useState } from "react";

import BackButton from "../components/BackButton";
import ContentButton from "../components/ContentButton";
import Background from "../components/Background";
import Bench from "../components/Bench";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Document, Page } from "react-pdf";
import pdf from "../pdf/ArdashWorksheet-MVP Student Handbook_FinalPages_3Feb21.pdf";
//! This PDF must be import to display

const Wrapper = styled.div`
  height: 100%;
  .pdfViewer {
    position: absolute;
    overflow-y: auto;
    width: auto;
    right: 15%;
    z-index: 99;
    max-height: 100%;
    bottom: 220px;
    top: 15%;
    @media screen and (max-width: 1180px) and (max-height: 500px) {
      bottom: 90px;
    }
    .close-button {
      z-index: 9;
    }
  }
  .button.hollow.primary {
    z-index: 1000;
  }

  .bench {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .characterViewer {
    position: absolute;
    left: 15%;
    bottom: 10%;
    top: 5%;
    width: 500px;
  }
  .edit-btn {
    position: absolute;
    right: 20%;
    bottom: 10%;
    @media (max-width: 768px) {
      bottom: 3%;
    }
  }
  @media (max-width: 1600px) {
    .pdfViewer {
      right: 10%;
    }
    .characterViewer {
      left: 10%;
    }
  }

  @media (max-width: 1380px) {
    .pdfViewer {
      right: 5%;
    }
    .characterViewer {
      left: 5%;
    }
  }

  @media (max-width: 1280px) {
    .characterViewer {
      left: 10%;
      bottom: 10%;
      top: unset;
      width: 350px;
    }
  }
  @media (max-width: 991px) {
    .characterViewer {
      width: 295px;
    }
  }
  @media (max-width: 900px) {
    .characterViewer {
      left: 5%;
    }
  }
  @media (max-width: 768px) {
    .characterViewer {
      left: 0;
      width: 260px;
    }
  }
  @media (max-width: 1100px) {
    .pdfViewer {
      right: 5%;
      margin: auto;
      max-width: 450px;
      bottom: 159px;
      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  @media (max-width: 991px) {
    .pdfViewer {
      bottom: 98px;
    }
  }
  @media (max-width: 768px) {
    .pdfViewer {
      bottom: -30px;
      max-width: 350px;
    }
  }
  @media (max-width: 680px) {
    .pdfViewer {
      right: 15px;
    }
  }
  @media (max-width: 600px) {
    .pdfViewer {
      right: 10px;
      bottom: -40px;
    }

    .characterViewer {
      left: 0;
      width: 220px;
    }

    .edit-btn {
      right: 0;
      bottom: 50px;
      left: 0;
      margin: auto;
      width: calc(100% - 20px);
    }
  }
  @media (max-width: 560px) {
    .pdfViewer {
      max-width: 100%;
      left: 10px;
    }
    .characterViewer {
      display: none;
    }
  }
  @media (max-width: 812px) and (max-height: 480px) {
    .characterViewer {
      left: 20px;
      width: 190px;
    }
    .pdfViewer {
      bottom: -35%;
      max-width: 350px;
      width: 100%;
    }
  }
`;

const Ardash = () => {
  const chra = localStorage.getItem("character");
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <Wrapper>
      <Background>
        <div className="character-name">
          <img
            src={JSON.parse(chra).image.source}
            alt={JSON.parse(chra).image.alt}
            className="characterViewer"
          />
        </div>

        <Document
          file={pdf}
          className="pdfViewer"
          options={{ workerSrc: "/public/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {/* <button class="close-button">
            <img src="images/icons/close.png" class="closebutton" />
          </button> */}
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              className="something"
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
        <a
          width="320"
          height="240"
          className="hollow button primary edit-btn"
          href={pdf}
          download="ArdashWorksheet-MVP Student Handbook_FinalPages_3Feb21"
        >
          Download PDF Here
        </a>

        <Bench className="bench"></Bench>
      </Background>
      <BackButton />
      <ContentButton />
    </Wrapper>
  );
};
export default withRouter(Ardash);
