import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ChapterService from "../services/ChapterService";
import ReactPlayer from "react-player";
import AudioService from "../services/AudioService";
import styled from "styled-components";
import ToolGroup from "../components/ToolGroup";
import { useHistory } from "react-router-dom";
import pdf1 from "../pdf/ArdashWorksheet-MVP Student Handbook_FinalPages_3Feb21.pdf";
import pdf2 from "../pdf/CornelliaWorksheet-MVP Student Handbook_FinalPages_3Feb21.pdf";
import pdf3 from "../pdf/ViviWorksheet-MVP Student Handbook_FinalPages_3Feb21.pdf";

import _, { filter } from "lodash";
const Wrapper = styled(Modal)`
  .close-button {
    z-index: 9;
  }
  .other-title {
    color: #676767;
  }
  .tools {
    width: auto;
    position: unset;
    margin: auto;
    position: unset;
    li {
      padding: 0 10px;
    }
  }
  .popup-header {
    position: relative;
  }
  .popup-name {
    width: 70%;
    padding-top: 20px;
    h2,
    h4 {
      padding-left: 16px;
      margin: 0;
    }
  }
  .popup-img {
    width: 30%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f1ebdf;
  }
  .modal-dialog .modal-content {
    .edit-btn {
      .accordion-content {
        height: 150px;
        padding: 0;
        width: 100%;
        background-position: center;
        margin: 0;
      }
    }
  }
  .modal-dialog {
    max-width: 80%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    .modal-content {
      border: none;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: auto;
      .portrait {
        width: 134px;
        height: auto;
      }

      .accordion-title {
        background-size: cover;
        width: 30%;
        padding: 21px 20px;
        font-size: 16px;
        color: #000000;
        background-repeat: no-repeat;
        background-position: right;
        background-color: #e5e0c8;
        border-bottom: 1px solid #fff;
      }
      .accordion-container {
        width: 70%;
        position: absolute;
        left: 30%;
        top: 0;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .accordion-content {
        width: 100%;
        height: 40%;
        font-size: 20px;
        position: unset;
        padding-bottom: 10px;
      }
      .bold-content {
        font-weight: bold;
        font-size: 16px;
        position: unset;
        width: 100%;
      }
      .character-footer {
        display: flex;
        justify-content: center;
        .button {
          width: 200px;
          margin: 10px;
        }

        @media (min-width: 690px) {
          display: block;
          padding-left: 30%;
        }
      }

      @media (max-width: 812px) and (max-height: 450px) {
        height: 725px;
      }
    }
  }
  @media (max-width: 690px) {
    .popup-img {
      padding: 20px;
    }
    .character-name {
      background-color: #f1ebdf;
    }
    .modal-dialog {
      .modal-content {
        .accordion-title {
          width: 100%;
        }
        .accordion-container {
          width: 100%;
          position: unset;
          .accordion-content {
            padding-top: 20px;
          }
          .bold-content {
            font-weight: bold;
            font-size: 16px;
            position: unset;
            width: 100%;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    .popup-img {
      padding: 0;
    }
    .modal-dialog .modal-content .accordion-content {
      width: 100%;
      font-size: 13px;
    }
    .modal-dialog .modal-content .bold-content {
      font-size: 14px;
    }
    .modal-dialog .modal-content .accordion-title {
      font-size: 14px;
    }
    .modal-dialog .modal-content .accordion-content {
      font-size: 14px;
    }
    .fontSize {
      font-size: 22px;
    }

    .modal-dialog .modal-content .character-footer .my-btn {
      width: 130px;
      font-size: 16px;
      padding: 10px;
    }
  }
  @media (min-width: 600px) {
    .modal-dialog .modal-content .character-footer .my-btn {
      width: 150px;
      font-size: 16px;
      padding: 10px;
    }
    .modal-dialog .modal-content .accordion-content {
      font-size: 14px;
    }
  }
  @media (min-width: 768px) {
    .modal-dialog .modal-content .character-footer .my-btn {
      width: 170px;
      font-size: 20px;
      padding: 12px;
    }

    .fontSize {
      font-size: 30px;
    }
    .modal-dialog .modal-content .accordion-content {
      font-size: 18px;
    }
    .modal-dialog .modal-content .bold-content {
      font-size: 20px;
    }
  }

  @media (min-width: 1024px) {
    .bold-content {
      bottom: 80px;
    }
  }
  @media (min-width: 1366px) {
    .bold-content {
      bottom: 100px;
    }

    .fontSize {
      font-size: 30px;
    }
  }
`;

export default ({ character, show, onClose, filteredTool }) => {
  const { getCharacters, getTools, team, setInventoryTools, inventoryTools } =
    ChapterService.useContainer();
  const { mute, volume } = AudioService.useContainer();
  const { setTeam } = ChapterService.useContainer();
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();

  const handleClick = (index) => {
    setActiveIndex(index);
    if (index === activeIndex) setActiveIndex(null);
  };
  const [] = useState(0);
  const toolList = getTools();

  const handleAdd = (tool) => {
    setInventoryTools([...inventoryTools, tool]);
  };
  const handleRemove = (tool) => {
    setInventoryTools(
      inventoryTools.filter(function (value, index, arr) {
        return value.id !== tool.id;
      })
    );
  };
  const handleUse = (link) => {
    window.open(link, "_blank");
  };
  const handleVideo = (target) => {
    window.open(target, "_blank");
  };
  const handleJoin = () => {
    localStorage.setItem("character", JSON.stringify(character));
    setTeam(character.id);
    if (character.id == "ardash") {
      history.push("/ardash");
    } else if (character.id == "cornelia") {
      history.push("/cornelia");
    } else if (character.id == "vivi") {
      history.push("/vivi");
    }
    onClose();
  };
  const setUrlPdf = () => {
    if (character.id === "ardash") {
      return pdf1;
    } else if (character.id === "vivi") {
      return pdf3;
    } else return pdf2;
  };
  return (
    <Wrapper show={show} onHide={() => onClose()}>
      <div
        className="card character-popup"
        style={{ backgroundImage: `url(${character.image.background})` }}
      >
        <div className="character-popup-content">
          <ReactPlayer
            url={character.audio}
            volume={volume}
            muted={mute}
            height="0"
            playing
            playsinline
          />
          <div class="popup-header">
            <div className="character-name">
              <div className="popup-img">
                {character.portrait && (
                  <img
                    src={character.portrait.source}
                    alt={character.portrait.alt}
                    className="portrait"
                  />
                )}
              </div>
              <div className="popup-name">
                <h2>{character.name}</h2>
                <br />
                <h4 className="subheader">
                  <q className="fontSize">{character.quote}</q>
                </h4>
              </div>
            </div>
            <button
              className="close-button"
              aria-label="Close alert"
              type="button"
              data-close
              onClick={() => onClose()}
            >
              <img src="images/icons/close.png" className="closebutton" />
            </button>
          </div>
          <ul
            className="accordion character-question"
            data-accordion
            data-allow-all-closed="false"
          >
            {character.questions &&
              character.questions.map((question, index) => {
                return (
                  <li
                    className="accordion-item"
                    data-accordion-item
                    key={index}
                  >
                    <a
                      className="accordion-title other-title"
                      onClick={() => handleClick(index)}
                      style={
                        index !== null && index === activeIndex
                          ? {
                              backgroundImage: `url(${character.image.selected})`,
                            }
                          : { backgroundImage: "none" }
                      }
                    >
                      {question.text}
                    </a>
                    <div className="accordion-container">
                      <p
                        className="accordion-content"
                        data-tab-content
                        style={
                          index !== null && index === activeIndex
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {question.answer}
                      </p>

                      <p
                        className="bold-content"
                        data-tab-content
                        style={
                          index !== null &&
                          index === activeIndex &&
                          activeIndex == 0
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {question.bold}
                      </p>
                      <div
                        style={
                          index !== null &&
                          index === activeIndex &&
                          activeIndex == 2
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <ToolGroup
                          tools={filteredTool.filteredTool}
                          alignment="center"
                          justify="center"
                          className="tools"
                          interactable="true"
                          onAdd={handleAdd}
                          onRemove={handleRemove}
                          onUse={handleUse}
                          inventory={inventoryTools}
                        />
                      </div>

                      <p
                        className="bold-content"
                        data-tab-content
                        style={
                          index !== null &&
                          index === activeIndex &&
                          activeIndex == 2
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {question.bold2}
                      </p>
                      <div className="edit-btn">
                        <button
                          width="320"
                          height="240"
                          className="accordion-content"
                          data-tab-content
                          onClick={() => handleVideo(question.video)}
                          style={
                            index !== null &&
                            index === activeIndex &&
                            activeIndex == 3
                              ? {
                                  display: "block",
                                  backgroundImage: `url(${question.videoImg})`,
                                  backgroundSize: "contain",
                                  backgroundRepeat: "no-repeat",
                                  marginLeft: "0",
                                  marginTop: "0",
                                }
                              : { display: "none" }
                          }
                        ></button>
                      </div>
                    </div>
                    {/* <button
                    className="hollow button primary"
                    onClick={() => {
                      handleJoin();
                    }}
                    style={
                      index !== null &&
                      index === activeIndex &&
                      activeIndex == 3
                        ? {
                            display: "block",
                            textAlign: "center",
                            margin: "auto",
                            marginTop: "7%",
                          }
                        : { display: "none" }
                    }
                  >
                    INVESTIGATE WITH ME
                  </button> */}
                  </li>
                );
              })}
          </ul>
          {character.team && (
            <div className="character-footer">
              <a
                className="hollow button primary my-btn"
                onClick={() => handleJoin()}
                // href={setUrlPdf()}
                // download="Teacher Handbook: The Case of The Starving Slum Lane Students and the Empty School Canteen.pdf"
              >
                JOIN TEAM
              </a>
              <button
                className="hollow button primary my-btn"
                onClick={() => onClose()}
              >
                MAYBE LATER
              </button>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
