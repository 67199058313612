import React from "react";
import { Modal } from "react-bootstrap";
import ChapterService from "../services/ChapterService";
import styled from "styled-components";
import _ from "lodash";
import { inventoryCapacity } from "../constants";
const Wrapper = styled(Modal)`
  .modal-dialog {
    max-width: 80%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    .modal-content {
      border: none;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: auto;
      .close-button {
        z-index: 9;
      }
      .tool-button {
        margin: 10px;
        @media (max-width: 600px) {
          width: 90px;
          padding: 5px;
        }
        @media (min-width: 768px) {
          width: 120px;
        }
      }
    }
    .close-button {
      z-index: 9;
    }
  }
  .tool__content {
    width: calc(100% - 280px);
    float: left;
  }

  img.custom-img.Wrist.Gadget {
    width: 200px;
  }
  img.custom-img.Petri.Dish {
    width: 288px;
  }
  img.custom-img.Carlin.the.Mouse {
    width: 280px;
  }
  .tool--parts--img {
    position: relative;
    width: 280px;
    float: left;
    margin-top: 10%;
    .tool-part {
      &.btn-04 {
        position: absolute;
        left: 0;
        top: 0;
      }
      &.btn-05 {
        top: 10px;
        right: 5px;
      }
      &.btn-16 {
        left: 55px;
        top: 70px;
      }
      &.btn-15 {
        right: 0;
      }

      &.btn-01 {
        left: 1rem;
        top: 80px;
      }
      &.btn-02 {
        left: 5rem;
        top: 50px;
      }
      &.btn-03 {
        right: 6rem;
        top: 100px;
      }

      &.btn-06 {
        right: 10rem;
        top: -1rem;
      }
      &.btn-07 {
        right: 6rem;
      }
      &.btn-08 {
        top: -1rem;
        right: 4rem;
      }
      &.btn-09 {
        left: 25%;
        top: 2.5rem;
      }
      &.btn-10 {
        top: 8rem;
      }

      &.btn-11 {
        right: 7rem;
      }
      &.btn-12 {
        left: 3rem;
      }
      &.btn-13 {
        left: 8.5rem;
        top: 8rem;
      }
      &.btn-14 {
        right: 4.8rem;
        top: 3rem;
      }
      @media (max-width: 768px) {
        &.btn-04 {
          left: 0;
          top: -5px;
        }
        &.btn-05 {
          right: 0;
          top: 10px;
        }
        &.btn-16 {
          left: 45px;
          top: 55px;
        }
        &.btn-15 {
          right: 0;
          top: 10px;
        }

        &.btn-01 {
          left: 5px;
          top: 45px;
        }
        &.btn-02 {
          left: 4.2rem;
          top: 50px;
        }
        &.btn-03 {
          right: 4.5rem;
          top: 24px;
        }

        &.btn-06 {
          right: 3.8rem;
          top: -0.5rem;
        }
        &.btn-07 {
          right: 0;
        }
        &.btn-08 {
          top: -5px;
          right: -2px;
        }
        &.btn-09 {
          left: 25%;
          top: 18px;
        }
        &.btn-10 {
          top: 4rem;
          right: 13px;
        }

        &.btn-11 {
          right: 3.5rem;
          top: 1rem;
        }
        &.btn-12 {
          left: 28px;
          top: 10px;
        }
        &.btn-13 {
          left: 70px;
          top: 60px;
        }
        &.btn-14 {
          right: 37px;
          top: 24px;
        }
      }
    }
  }
  .custom-img-mgt {
    margin-top: 0;
  }
  .test1 {
    z-index: 1;
    margin-top: 65px;
    padding: 0 10px 20px 20px;
  }
  @media (max-width: 768px) {
    .tool__content {
      width: calc(100% - 160px);
    }
    .tool--parts--img {
      width: 145px;
    }
    img.custom-img.Flying.Egg.Spy,
    img.custom-img.Tool.belt,
    img.custom-img.Goggle,
    img.custom-img.Hover.Board,
    img.custom-img.Tele.Tube {
      width: 160px;
    }

    .tool-part {
      width: 30px;
      height: 30px;
    }
  }
`;
export default ({ tool, show, onAdd, onRemove, onUse, onClose }) => {
  const { inventoryTools } = ChapterService.useContainer();
  const handleAdd = (tool) => {
    onAdd(tool);
    onClose();
  };
  // const handleUse = (tool) => {
  //   onClose();
  //   onUse(tool.link);
  // };
  const handleRemove = (tool) => {
    onRemove(tool);
    onClose();
  };

  return (
    <Wrapper show={show} onHide={() => onClose()} animation={false}>
      <div
        className="card tool-popup"
        style={{
          backgroundImage: `url("../images/backgrounds/Optimized-ToolPopups_NoText.png")`,
        }}
      >
        <div className="test">
          <button
            className="close-button"
            aria-label="Close alert"
            type="button"
            data-close
            onClick={() => onClose()}
          >
            <img
              src="images/icons/close.png"
              className="closebutton"
              alt="close"
            />
          </button>
          <div className="tool-name" id="HoverBoardPopup">
            <h2>{tool.name}</h2>
          </div>
        </div>
        <div className="test1">
          <div className="tool__content-flex">
            <div className="tool__content">
              <div className={`${tool.image.size}-content tool-content`}>
                <p className="tool-info">{tool.description}</p>
                {tool.characteristics && (
                  <div className="tool-chara">
                    <h4 className="tool-title">{tool.tittlee}</h4>
                    {tool.characteristics.map((characteristics, index) => {
                      // prettier-ignore
                      const characteristicsText = characteristics.text.replace(/([^.])$/, '$1.');
                      return (
                        <ul key={index}>
                          <li>{characteristicsText}</li>
                        </ul>
                      );
                    })}
                  </div>
                )}
                {tool.link && (
                  <div className="tool-link">
                    {tool.link.map((link, index) => {
                      return (
                        <a
                          className="hollow button primary tool-button"
                          href={link.url}
                          key={index}
                        >
                          {link.text}
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div
              className={`tool--parts--img ${
                tool?.id === "flyEgg" ? "custom-img-mgt" : ""
              }`}
            >
              <img
                src={tool.image.source}
                alt={tool.image.alt}
                className={`custom-img ${tool.image.alt}`}
              />
              {tool.parts && (
                <div className="tool--parts">
                  {tool.parts.map((part, index) => {
                    return (
                      <a
                        href={part.link}
                        target="_blank"
                        className={`tool-part dotted-circle ${part.class}`}
                        data-tooltip
                        tabIndex="2"
                        title={part.text}
                        // key={index}
                        key={part?.class || index}
                      ></a>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="tool-animation">
            <button
              className="hollow button primary tool-button"
              onClick={() => handleAdd(tool)}
              disabled={
                _.find(inventoryTools, (t) => t.id === tool.id) ||
                inventoryTools.length >= inventoryCapacity
              }
            >
              ADD
            </button>
            {/* <button
              className="hollow button primary tool-button"
              onClick={() => handleUse(tool)}
              disabled={!_.find(inventoryTools, (t) => t.id === tool.id)}
            >
              USE
            </button> */}
            <button
              className="hollow button primary tool-button"
              onClick={() => handleRemove(tool)}
              disabled={!_.find(inventoryTools, (t) => t.id === tool.id)}
            >
              RETURN
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
