import React, { useEffect, useState } from "react";
import IconButton from "./IconButton";
import SpeechBubble from "./SpeechBubble";
import ContentService from "../services/ContentService";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { hintDelay } from "../constants";
import _ from "lodash";
const Wrapper = styled.div`
  position: absolute;
  bottom: 25px;
  left: 25px;
  pointer-events: all;
  @media (max-width: 768px) and (min-width: 600px) {
    bottom: 0px;
  }
  img {
    width: 100px;
    height: auto;
    @media (max-width: 768px) {
      width: 50px;
    }

    @media (min-width: 769px) {
      width: 100px;
    }
  }
  .button.secondary {
    z-index: 99;
  }
  .left-speech-bubble {
    z-index: 123456789;
    position: absolute;
    bottom: 35px;
    left: 160px;
    width: 500px;
    text-align: left;

    @media (max-width: 600px) {
      bottom: -10px;
      left: 120px;
      width: 235px;
      font-size: 16px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 400px;
    }
    @media (max-width: 812px) and (max-height: 450px) {
      width: 200px;
      font-size: 14px;
      bottom: 0px;
      left: 115px;
    }
  }
  .highlight {
    animation: border-flicker 2s linear infinite;
  }
  @keyframes border-flicker {
    0% {
      box-shadow: 0px 0px 10px 5px rgba(240, 255, 0, 0);
    }
    20% {
      box-shadow: 0px 0px 10px 5px rgba(240, 255, 0, 0.5);
    }
    40% {
      box-shadow: 0px 0px 10px 5px rgba(240, 255, 0, 1);
    }
    60% {
      box-shadow: 0px 0px 10px 5px rgba(240, 255, 0, 1);
    }
    80% {
      box-shadow: 0px 0px 10px 5px rgba(240, 255, 0, 0.5);
    }
    100% {
      box-shadow: 0px 0px 10px 5px rgba(240, 255, 0, 0);
    }
  }
`;
const HintButton = ({ history }) => {
  const { content } = ContentService.useContainer();
  const hints = content.hints;
  const [showHelp, setShowHelp] = useState(false);
  const [path, setPath] = useState(history.location.pathname);
  const [hint, setHint] = useState(null);
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    if (hint != null) {
      if (hint.showHelp == "true") {
        setShowHelp(true);
      }
    }
  }, [hint]);
  useEffect(() => {
    setShowHelp(false);

    setHint(_.find(hints, (h) => h.path === history.location.pathname));
    if (history.location.pathname !== path) {
      setTimer(0);
      setPath(history.location.pathname);
    }
  }, [history.location.pathname]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer < hintDelay && !showHelp) {
        setTimer((timer) => timer + 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer, showHelp]);
  const handleClick = () => {
    setShowHelp(!showHelp);
    setTimer(0);
  };

  if (hint === undefined) {
    return null;
  }

  return (
    <Wrapper>
      <IconButton
        className={timer >= hintDelay ? "highlight" : ""}
        onClick={() => handleClick()}
      >
        <img src="images/icons/help.png" alt="help" />
        {showHelp && (
          <SpeechBubble
            className={`left-speech-bubble`}
            alignment="left"
            text={hint.text}
          />
        )}
      </IconButton>
    </Wrapper>
  );
};
export default withRouter(HintButton);
