import React, { useState, useEffect } from "react";
import Background from "../components/Background";
import Bench from "../components/Bench";
import BackButton from "../components/BackButton";
import Conversation from "../components/Conversation";
import PrimaryButton from "../components/PrimaryButton";
import ContentService from "../services/ContentService";
import NavigationService from "../services/NavigationService";
import ChapterService from "../services/ChapterService";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import posed from "react-pose";
import HintButton from "../components/HintButton";
import AudioService from "../services/AudioService";
import KooriFlag from "../components/KooriFlag.js";



const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .chapters {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      list-style: none;
      padding: 0;
      li {
        padding: 20px;
        @media (max-width: 600px) {
          text-align: center;
          button {
            width: 50% !important;
          }
        }
        @media (min-width: 600px) and (max-width: 940px){
          text-align: center;
          button {
            width: 50% !important;
          }
        }
        button {
          width: 100%;
          padding: 0;
          background-color: transparent;
          border: none;
          img {
            width: 200px;
            height: auto;
          }
        }
      }
    }
  }
  .bench {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 600px) {
    .eidt-btn {
      margin-bottom: 55px;
      button {
        padding: 10px;
        font-size: 16px;
      }
    }
  }
`;

const ContentBtn = styled.div`
  border: 4px solid #5daa86;
  color: #00bfa5;
  background-image: url("../images/patterns/DownloadNotes_NoTextNoBorderNormal.png");
  padding: 0.85em 1em;
  border-radius: 20px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 999;
  @media (max-width: 600px) {
    font-size: 20px;

  }

  @media (min-width: 600px) {
    font-size: 1rem;
  }


`
const ChapterContainer = posed.div({
  open: {
    scaleX: 1,
    scaleY: 1,
    staggerChildren: 100,
    transition: { duration: 1000 },
  },
  closed: {
    scaleX: 0,
    scaleY: 0,
    transition: { duration: 1000 },
  },
});
const Chapter = posed.li({
  open: {
    opacity: 1,
    transition: { duration: 1000 },
  },
  closed: {
    opacity: 0,
    transition: { duration: 1000 },
  },
});
const LevelSelectScreen = ({ history }) => {
  const { content } = ContentService.useContainer();
  const { setShowSkip, setShowBack } = NavigationService.useContainer();
  const { setChapter } = ChapterService.useContainer();
  const { setMute, setVolume} = AudioService.useContainer();
  const [reload, setReload] = useState(false);
  const [showChapters, setShowChapters] = useState(false);

  useEffect(() => {
    setShowBack(true);
  }, [setShowSkip, setShowBack]);

  
  
  useEffect(() => {
    setMute(false);
    setVolume(0);
    setReload(true);

    // eslint-disable-next-line
  }, [])

  
  
  useEffect(() => {
    if(reload){
      document.getElementById("muteBtn").click();
      document.getElementById("click-mute").click();
      setVolume(1);
      setReload(false);
    }

    // eslint-disable-next-line
  }, [reload])
  
  

  const handleConversationEnd = () => {
    setShowChapters(true);
    setShowSkip(false);
  };
  const handleSelectChapter = (option) => {
    setChapter(option);
    history.push("/portal");
  };

  return (
    <Wrapper>
      <Background>
        <Conversation
          skip={showChapters}
          conversation={content.conversation}
          onConversationEnd={() => handleConversationEnd()}
        />
        <ChapterContainer
          className="chapters"
          pose={showChapters ? "open" : "closed"}
        >
          <ul>
            {content.chapters.map((chapter, index) => {
              return (
                <Chapter key={index}>
                  <Button
                    disabled={!chapter.enabled}
                    onClick={() => handleSelectChapter(chapter)}
                    
                  >
                    {/* <img
                      src={chapter.image.source}
                      alt={chapter.image.alt}
                      className="chapter-button"
                    /> */}
                    <ContentBtn>
                    EPISODE 1 - The Case of The Starving Slum-Lanes Students
                    </ContentBtn>
                  </Button>
                </Chapter>
              );
            })}
          </ul>
        </ChapterContainer>
        <HintButton />
        <Bench className="bench">
          {!showChapters && (
            <PrimaryButton
              className="eidt-btn"
              onClick={() => setShowChapters(true)}
            >
              SKIP INTRODUCTION
            </PrimaryButton>
          )}
        </Bench>
      </Background>
      <BackButton />
       <KooriFlag flagClassName="koori-flag-LevelSelect" />
    </Wrapper>
  );
};
export default withRouter(LevelSelectScreen);
