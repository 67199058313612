import React, { useRef, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import AudioService from "../services/AudioService";
import ContentService from "../services/ContentService";

export default () => {
  const { muteBackground, mute, volume } = AudioService.useContainer();
  const { content } = ContentService.useContainer();
  return (
    <ReactPlayer
      url={content.background}
      playing={true}
      loop
      muted={mute || muteBackground}
      volume={volume}
      height={0}
      width={0}
      playsinline
    />
  );
};
