import React from "react";
import IconButton from "./IconButton";
import styled from "styled-components";
import AudioService from "../services/AudioService";
const Wrapper = styled(IconButton)`
  position: absolute;
  right: 25px;
  top: 5px;
  pointer-events: all;
  z-index: 99;
  padding-right: 0;
  @media (max-width: 600px) {
    top: 10px;
  }
`;

export default () => {
  const { mute, setMute, setVolume } = AudioService.useContainer();
  return (
    <Wrapper
      className="mute-button"
      id="muteBtn"
      onClick={() => {
        setMute(!mute);
        setVolume(!mute ? 1 : 0);
      }}
    >
      <span className="show-for-sr">Mute</span>
      <span aria-hidden="true">
        <img
          src={!mute ? "/images/icons/mute.png" : "/images/icons/unmute.png"}
          alt={mute ? "mute" : "unmute"}
          className="settingbutton"
        />
      </span>
    </Wrapper>
  );
};
